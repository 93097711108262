import { atom, selector, selectorFamily } from "recoil";

import type { StateEnvironmentUser } from "@models/types/StateEnvironment";
import { userUriState } from "@states/auth.state";
import successOrUndefined from "smavesto.core/lib/utils/processing/successOrUndefined";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import { getRequestState, useRefreshState } from "./request.state";

export const showCustomEducationState = atom({
  key: "showCustomEducation",
  default: false
});

export const additionalExperiencesState = atom<string[]>({
  key: "additionalExperiences",
  default: []
});

export const showProfileVerificationUserControlState = atom({
  key: "showProfileVerificationUserControl",
  default: true
});

export const knowledgeState = selectorFamily({
  key: "knowledge",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }) => {
      const userUri = get(userUriState(type));

      get(getRequestState("knowledge", userUri));

      return userUri
        ? successOrUndefined(
            await smavestoCore.services.user.getKnowledge(userUri)
          )
        : undefined;
    }
});

export type ExperienceRadioState = {
  equities: { knowhow: boolean | undefined; noticed: boolean | undefined };
  bonds: { knowhow: boolean | undefined; noticed: boolean | undefined };
  commodities: { knowhow: boolean | undefined; noticed: boolean | undefined };
};

export const experienceRadioState = atom<ExperienceRadioState>({
  key: "experienceRadio",
  default: {
    equities: { knowhow: undefined, noticed: undefined },
    bonds: { knowhow: undefined, noticed: undefined },
    commodities: { knowhow: undefined, noticed: undefined }
  }
});

export const showKnowledgeVideo = selector({
  key: "showKnowledgeVideo",
  get: ({ get }) => {
    const experienceRadio = get(experienceRadioState);

    return Object.values(experienceRadio).some(
      v => v.knowhow === false && v.noticed === false
    );
  }
});

export const showProfileVerificationState = selectorFamily({
  key: "showProfileVerification",
  get:
    ({ type, pathname }: { type: StateEnvironmentUser; pathname: string }) =>
    ({ get }) => {
      const knowledge = get(knowledgeState(type));

      const userControl = get(showProfileVerificationUserControlState);

      const isShowModal =
        !!knowledge &&
        "wphgValid" in knowledge &&
        !knowledge.wphgValid &&
        userControl;

      return pathname === "/dashboard/profile" ? false : isShowModal;
    }
});

export const useRefreshKnowledge = (type: StateEnvironmentUser) =>
  useRefreshState("knowledge", type);
