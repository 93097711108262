var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"124210a684919f3b73835f4f091e82d695254420"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { pkg } from "@src/configs/pkg.config";
import { isDebugEnvironment } from "@src/utils/environment/isDebugEnvironment";

Sentry.init({
  release: `smavesto-webapp@${pkg.version}`,
  environment: isDebugEnvironment ? "development" : "production",

  dsn: "https://b3b3748a925b4f36afe963940ebf8edc@o1428750.ingest.sentry.io/4503885716848640",

  integrations: [new Sentry.BrowserTracing()]
});
