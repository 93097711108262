import { Button } from "antd";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import {
  currentDepotOnboardingState,
  currentDepotState
} from "@states/current.depot.state";
import { loggedInUserOnboardingState } from "@states/onboarding.state";
import { useRefreshDirectDeposits } from "@states/simulate.state";

import { useAsyncSelector } from "@hooks/useAsyncSelector";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import PopoverInfo from "@src/components/form/popover-info/PopoverInfo";

import { depotsState, useRefreshDepot } from "@src/state/depot.state";
import useThemedModal from "@src/theme/hook/useThemedModal";
import { loggedInUserUriState } from "@states/auth.state";
import { assertNotEmpty } from "smavesto.core";
import isJuniorDepot from "smavesto.core/lib/utils/typeguards/isJuniorDepot";
import CancellationTooltip from "../../form/tooltips/CancellationTooltip";

export function LiveSimulateList() {
  const { openModal, contextHolder } = useThemedModal(true);

  const [, depots] = useAsyncSelector(depotsState);

  const [, currentDepot] = useAsyncSelector(currentDepotState);

  const currentDepotUserUri = currentDepot?.userUri;

  const [, loggedInUserUri] = useAsyncSelector(loggedInUserUriState);

  const [, loggedInUserOnboarding] = useAsyncSelector(
    loggedInUserOnboardingState
  );

  const [, onboarding] = useAsyncSelector(currentDepotOnboardingState);

  const refreshDepots = useRefreshDepot("loggedInUser");

  const refreshDirectDeposits = useRefreshDirectDeposits("currentDepotOwner");

  const legitimationIntervalRef = useRef<NodeJS.Timer | null>(null);

  const liveDepotIntervalRef = useRef<NodeJS.Timer | null>(null);

  const refreshLiveDepotDependencies = () => {
    refreshDepots();
    refreshDirectDeposits();
  };

  const [loadingSendAllMails, setLoadingSendAllMails] = useState(false);

  const [loadingLegitimation, setLoadingLegitimation] = useState(false);

  const [loadingOpenDepot, setLoadingOpenDepot] = useState(false);

  useEffect(() => {
    if (onboarding?.legitimationCompleted) {
      legitimationIntervalRef.current &&
        clearInterval(legitimationIntervalRef.current);
    }

    if (onboarding?.liveDepot) {
      liveDepotIntervalRef.current &&
        clearInterval(liveDepotIntervalRef.current);
    }
  }, [onboarding?.liveDepot, onboarding?.legitimationCompleted]);

  const onSimulateExemptionOrder = async () => {
    if (!currentDepotUserUri) return false;

    await smavestoCore.services.simulate.freistellungsAuftragVerbrauch(
      currentDepotUserUri,
      123,
      456,
      {
        dividendTaxRefundType: "CUSTOM",
        amountCustom: 789,
        placeOrder: true
      }
    );

    return true;
  };

  const onSimulateExpiredInvitation = async () => {
    assertNotEmpty(currentDepotUserUri);

    await smavestoCore.services.test.setUnderageAccountOnboardingToExpired(
      currentDepotUserUri
    );

    openModal("info", {
      title: "Hinweis",
      content: `Der Endpunkt wurde aufgerufen, allerdings dauert es mindestens 15 Minuten, bis die DSER den Link tatsächlich als abgelaufen markiert. Diese Seite muss danach auch vollständig neu geladen werden.`
    });
  };

  const onSimulateLegitimation = async (userUri: string) => {
    if (!loggedInUserUri) return;

    setLoadingLegitimation(true);

    await smavestoCore.services.simulate.legitimation(userUri);

    refreshDepots();

    legitimationIntervalRef.current &&
      clearInterval(legitimationIntervalRef.current);

    legitimationIntervalRef.current = setInterval(() => {
      onboarding?.legitimationCompleted
        ? legitimationIntervalRef.current &&
          clearInterval(legitimationIntervalRef.current)
        : refreshDepots();
    }, 8000);
    setLoadingLegitimation(false);
  };

  const onSimulateExpiredLegitimation = async () => {
    if (!loggedInUserUri) return;

    setLoadingLegitimation(true);

    await smavestoCore.services.test.setLegitimationToExpired(loggedInUserUri);

    refreshDepots();

    legitimationIntervalRef.current &&
      clearInterval(legitimationIntervalRef.current);

    legitimationIntervalRef.current = setInterval(() => {
      onboarding?.legitimationCompleted
        ? legitimationIntervalRef.current &&
          clearInterval(legitimationIntervalRef.current)
        : refreshDepots();
    }, 8000);
    setLoadingLegitimation(false);
  };

  const onOpenDepot = async () => {
    if (!currentDepotUserUri) return;

    setLoadingOpenDepot(true);

    await smavestoCore.services.simulate.juristischeDepotEroeffnung(
      currentDepotUserUri
    );

    refreshLiveDepotDependencies();

    liveDepotIntervalRef.current && clearInterval(liveDepotIntervalRef.current);

    liveDepotIntervalRef.current = setInterval(() => {
      onboarding?.liveDepot
        ? liveDepotIntervalRef.current &&
          clearInterval(liveDepotIntervalRef.current)
        : refreshLiveDepotDependencies();
    }, 8000);
    setLoadingOpenDepot(false);
  };

  const onSimulateEmails = async () => {
    if (!currentDepotUserUri) return;

    setLoadingSendAllMails(true);

    await smavestoCore.services.simulate.sendAllEmails(currentDepotUserUri);

    setLoadingSendAllMails(false);
  };

  return (
    <SLiveSimulateList>
      <li>Alle Onboarding-Schritte beenden (Zusammenfassungabschließen)</li>
      <li>{`"Video-" oder "Vor Ort" wählen`}</li>
      <li>{`"Legitimation später durchführen" auswählen`}</li>
      <li>
        <Button
          style={{ width: 300, marginBottom: 10, height: 60 }}
          loading={loadingLegitimation}
          onClick={() => onSimulateLegitimation(loggedInUserUri!)}
        >
          Legitimation simulieren für:
          <br />
          {loggedInUserUri}
        </Button>
        {depots
          ?.filter(d => d.userUri !== loggedInUserUri)
          .map(depot => (
            <Button
              style={{ width: 300, marginBottom: 10, height: 60 }}
              loading={loadingLegitimation}
              onClick={() => onSimulateLegitimation(depot.userUri)}
            >
              Legitimation simulieren für:
              <br />
              {depot.depotOwnerFullName}
            </Button>
          ))}
      </li>
      <li>abmelden, 5 Minuten warten, wieder anmelden</li>
      <li>
        <CancellationTooltip>
          <Button
            style={{ width: 300 }}
            loading={loadingOpenDepot}
            disabled={onboarding?.liveDepot}
            onClick={onOpenDepot}
          >
            Juristisches Depot eröffnen
          </Button>
        </CancellationTooltip>
      </li>
      <li>abmelden, 10 Minuten warten, wieder anmelden</li>
      <li>
        <CancellationTooltip>
          <Button
            style={{ width: 300 }}
            loading={loadingSendAllMails}
            onClick={onSimulateEmails}
          >
            Alle E-Mails senden
          </Button>
        </CancellationTooltip>
      </li>
      <li>
        Nach erfolgter Legitimation:
        <br />
        <Button
          style={{ width: 300 }}
          loading={loadingLegitimation}
          disabled={
            !(
              loggedInUserOnboarding?.atLeastOneOnboardingLegitimationIsCompleted ===
                true && loggedInUserOnboarding?.legitimationExpired === false
            )
          }
          onClick={onSimulateExpiredLegitimation}
        >
          Abgelaufene Legitimation simulieren{" "}
          <PopoverInfo
            title="Abgelaufene Legitimation"
            text="Simuliert für den angemeldeten Nutzer eine abgelaufene Legitimation, sodass bei einer neuen Depoteröffnung eine erneute Legitimation notwendig ist."
          />
        </Button>
      </li>
      <li>
        <CancellationTooltip>
          <Button style={{ width: 300 }} onClick={onSimulateExemptionOrder}>
            FSA-Nutzung setzen
          </Button>
        </CancellationTooltip>
      </li>
      {currentDepot && isJuniorDepot(currentDepot) && (
        <li>Nach Einladung eines gesetzl. Vertreters:</li>
      )}
      {currentDepot && isJuniorDepot(currentDepot) && (
        <li>
          <Button
            style={{ width: 300 }}
            loading={loadingLegitimation}
            disabled={onboarding?.secondGuardianInvitation !== "present"}
            onClick={onSimulateExpiredInvitation}
          >
            Abgel. Link für Vertreter simulieren
          </Button>
        </li>
      )}
      {contextHolder}
    </SLiveSimulateList>
  );
}

const SLiveSimulateList = styled.ol`
  padding-left: 16px;

  li {
    padding: 4px;
  }
`;
